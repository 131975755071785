@import url(https://fonts.googleapis.com/css?family=Exo:100,200,300,300i,400,500,600,700,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.loader{
    display: none
}

.loader.visible{
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    width: 40px;
    height: 40px;
}

.loader.visible .plain-circle{
    z-index: 10;
    display: block;
    position: absolute;
    left: 0; top: 0;
    width: 40px;
    height: 40px;
    border: 5px solid rgba(0,0,0,.1);
    border-radius: 50%;
    box-sizing: border-box;
}

.loader.visible .half-circle{
    z-index: 11;
    display: block;
    position: absolute;
    left: 0; top: 0;
    width: 40px;
    height: 40px;
    /* border-top: 5px solid #177BD1; */
    border-bottom: 5px solid #177BD1;
    border-radius: 50%;
    box-sizing: border-box;
    -webkit-animation: spin 1s ease-in-out infinite;
            animation: spin 1s ease-in-out infinite;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg)
    }
    to {
        -webkit-transform: rotate(720deg);
                transform: rotate(720deg)
    }
}

@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg)
    }
    to {
        -webkit-transform: rotate(720deg);
                transform: rotate(720deg)
    }
}

.auth{
    position: absolute;
    top: 0; left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #177BD1;
}
.auth .box-auth{
    width: 500px;
    min-height: 300px;
    padding: 20px;
    border-radius: 5px;
    /* border: thin solid #EFA959; */
    background-color: #FFFFFF;
}

.auth .h-40{
    height: 40px !important;
}

.form-group{
    border: 0 !important;
}
.App {
  font-family: "Exo",Arial, Helvetica, sans-serif;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.pl-20{
  padding-left: 20px;
}
.pt-20{
  padding-top: 20px;
}

.pr-20{
  padding-right: 20px;
}
.ml-20{
  margin-left: 20px;
}

.ml-15{
  margin-left: 15px;
}

.mr-20{
  margin-right: 20px;
}
.mt-20{
  margin-top: 20px;
}
.separator{
  border-bottom: 0.5px solid rgba(152, 152, 152, .3);
}
.text-center-h{
  text-align: center;
}
.text-right-h{
  text-align: right;
}

.text-left-h{
  text-align: left;
}

.text-left-h-description{
  text-align: left;
}

.font-size-table-data{
  font-size: 0.9rem;
  font-family: "Exo",Arial, Helvetica, sans-serif;
}

.deliver-style{
  font-size: 0.9rem;
  font-weight: 600;
  color:#000000;
  font-family: "Exo",Arial, Helvetica, sans-serif;
}

.mr-pag-15{
  margin-right: 15px;
}
.mt-pag-15{
  margin-top: 15px;
}
button, input, select, button:focus, input:focus, select:focus{
  outline: none !important;
}
.header{
   z-index: 11;
   position: fixed;
   display:flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   left: 0; top: 0;
   width: 100%;
   height: 80px;
   margin: 0; padding: 0;
   background-color: #FFFFFF;
   box-shadow: 0 1px 3px rgba(0,0,0,.1);
}
.header .logo{
   display: flex;
   align-items: center;
   justify-content: center;
   width: 130px;
   height: 80px;
   color: #555555;
   font-size: 1.3rem;
}
.bold{
   font-weight: bold;
}
.bloc{
   display: block;
}
.noti{
   position: relative;
   margin: 0 50px 0 0;
   padding: 0;
   width:30px;
   height:30px;
   background-image: url(/static/media/bell.52dd72dd.svg);
   /* background-size: cover; */
   background-position: center;
   background-repeat: no-repeat;
   -webkit-transform: scale(0.8);
           transform: scale(0.8)
}
.nb-noti{
   position: absolute;
   top:-13px;
   right: -13px;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 18px;
   border: thin solid #DB1B1B;
   border-radius: 50%;
   height: 18px;
   font-size: 0.8rem;
   font-weight: 800;
   color: #DB1B1B;
}
.right{
   display: flex;
   align-items: center;
   justify-content: center;
   margin-right: 30px;
}
.usr-block{
   display: flex;
   align-items: center;
}
.label-user{
   margin: 0 10px;
   font-weight: 300;
   font-size: 0.9rem;
   font-family: "Exo",Arial, Helvetica, sans-serif;
   color: #707070;
}
button.NavItem{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 130px;
    background-color: #FFFFFF;
    border-top: none;
    border-right: none;
    border-bottom: 0.5px solid rgba(0,0,0, .2);
    border-left: none;
    cursor: pointer;
    outline: none;
}

.content-btn-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
button.NavItem .label{
    margin-top: 15px; 
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    color: black;
}

button.NavItem.active .label{
    margin-top: 15px; 
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 0.8rem;
    font-weight: 900;
    color: #1A45D3;
}

button.NavItem.active::after{
    position: absolute;
    content: " ";
    display: block;
    top: 5%; bottom: 5%; left: 0;
    width: 5px;
    background-color: #1A45D3;
    border-radius: 15px;
}

.NavLink{
    z-index: 10;
    position: fixed;
    left: 0; top: 0;
    margin: 0;
    padding: 80px 0 0 0;
    width: 130px;
    height: 100vh;
    background-color: #FFFFFF;
    box-shadow: 1px 0 3px rgba(0,0,0,.1); 
}


.main-content{
    position: relative;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    padding-left: 150px;
    padding-top: 80px;
}
.main{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    margin: 0; 
    padding: 0;
    background-color: #F8F8F8;
}
.title-feature{
    color: #555555;
    width: 100%;
}
.h-title{
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-weight: bold;
}

button{
    border:none;
    background-color: #F8F8F8;
}

.TriItem{
    padding-right: 20px;
}


.search{
    padding: 10px 20px;
    width: 260px;
    height: 30px;
    background-color: #F0F0F0;
    border-radius: 50px;
    outline: none;
    border: none;
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 0.7rem;
    background-image: url(/static/media/icon-search.25874ae1.svg);
    background-repeat: no-repeat;
    background-position: top 50% right 20px;
}
.tri-search{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.title-tri{
    display: flex;
    align-items: flex-end;
    color: #555555;
    font-weight: bold;
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 0.7rem; 
    vertical-align: bottom;
    margin-right: 9px;
}

.content-left{
    display: flex;
}

.content-ct1{
    background-color: #F8F8F8;
    position: relative;
}




.DateItemList{
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border: 0.5px solid #707070;
    color:  #333333 ;
    overflow: hidden;
    font-family:  "Exo" ;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
}
.DateItemList.active{
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border: none;
    color:  #333333 ;
    overflow: hidden;
    font-family:  "Exo" ;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
}
.DateItemList .boxDay{
    display: flex;
    height: 30px;
    background-color: #CBCBCB;
    align-items: center;
    justify-content: center ;
}
.DateItemList .boxDayactive{
    display: flex;
    height: 30px;
    background-color:  #F56969 ;
    align-items: center ;
    justify-content: center ;
}
.DateItemList .boxMonth{
    display:  flex ;
    height: 20px;
    align-items: center ;
    justify-content: center ;
    border-top: 0.5px solid #707070;
    background-color:#AAA7A7 ;
}
.DateItemList .boxMonthactive{
    display: flex ;
    height: 20px;
    align-items: center ;
    justify-content: center ;
    background-color:#E23E3E ;
    border-top: 0.5px solid rgba(255,255,255,0.7);
}
.DateItemList .txtDay{
   font-family: "Exo" ;
   font-size: 23px;
   font-weight: bold ;
   color:  #333 ;
   margin-top: 5px;
}
.boxDayactive .txtDay{
    color:  #FFF ; 
}
.DateItemList .txtMonth{
   font-family: "Exo" ;
   font-size: 12px;
   font-weight: 900 ;
   color:  #333 ;
   text-transform: capitalize;
}
.boxMonthactive .txtMonth{
    color:  #FFF ; 
}
.tag-description{
    display: flex;
    align-items: center;
    border-radius: 15px;
    border: 1px solid ;
    box-sizing: border-box;
    padding-right: 5px;
    padding-left: 5px;
    margin-right: 10px;
    margin-bottom: 5px;
    color: #AAA7A7;
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 12px;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
  }

  .tag-description img{
    margin-right: 5px;
  }

  .ct-tag{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
table.table-data{
   position: relative;
   width: 100%;
   font-family: "Exo", Arial, Helvetica, sans-serif;
   font-size: 0.8rem;
   border-collapse:separate; 
   border-spacing: 0 5px;
}
table.table-data > thead > tr{
    border: 0.5px solid rgba(125, 125, 125, 0.2);
}
/* table.table-data > thead > tr > th:first-child{
   text-align: center;
} */
table.table-data tr{
    height: 50px;
    background-color: #FFFFFF;
    margin-bottom: 10px;
    box-sizing: border-box;
    border: 0.5px solid rgba(125, 125, 125, 0.2);
    cursor: pointer;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    white-space: nowrap;
}

table.table-data tbody tr:hover{
    box-shadow: 0 0 1px rgba(0,0,0,.6);
    border: 0.5px solid #707070;
}

.total-tva{
    color: #F56969;
}


.container-pagination{
    display: flex;
    justify-content: space-between;
    height: 35px;
    margin-top: 20px;
}
.container-pagination button{
    outline: none;
}
.pagination-left{
    display: flex;
    justify-content: space-between;
}

.onePage{
    border: 0.5px solid #555555;
    border-radius: 5px;
    height: 27px;
    width: 27px;
    margin-right: 15px; 
    color: #555555;
}

.container-pagination .active{
    border: 0.5px solid #DB1B1B;
    color: #DB1B1B;
}

.pagination-rigth{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 38px;
    min-width: 100px;
    color: #555555;
    font-size: 0.8rem;
}
.pagination-rigth .page-range{
    margin-right: 20px;
}
.pagination-rigth .dropdown{
    border: 0.5px solid #555555;
    border-radius: 25px;
    font-size: 0.8rem;
    width: 50px;
}
.pagination-rigth .dropdown-toggle{
    padding: 0 10px !important;
    font-size: 0.8rem;
}
.pagination-rigth .dropdown-toggle::after {
    display: inline-block;
    margin-left: 1.055em;
    vertical-align: .155em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

.nb-items{
    border-radius: 15px;
}


#page-numbers {
    list-style: none;
    display: flex;
}
  
#page-numbers > li {
    margin-right: 0.3em;
    color: blue;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
}

:disabled,
.disabled > img{
    outline: none;
    cursor: not-allowed;
    pointer-events: none;
    color:#fff;
    border-color: #a0a0a0;
    background-color: rgb(229, 229, 229) !important;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.disabled:focus{
    outline: none;
}


.container-footer{
    background-color: transparent;
    margin-bottom: 15px;
    margin-top: 25px;
}

.footer{
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 0.7rem;
    font-weight: 500;
    color: #000000;
    width: 316px;
}
.container-fluid .container-fluid{
  -webkit-transform: scale(0.7) translate(0%, -15%);
          transform: scale(0.7) translate(0%, -15%);
  zoom: 115%;
}

.form-data{
  margin-top: 25px;
  font-family: "Exo",Arial, Helvetica, sans-serif;
  color: #555555;
  font-size: 1em;
  font-weight: 500;
}

form > div:nth-of-type(2){
  box-sizing: border-box;
  border: 0.3px solid rgba(112, 112, 112, 0.3);
  /* margin-top: 10px; */
}

form > div:nth-of-type(3){
  margin-top: 20px;
}

form > div:nth-of-type(4){
  margin-top: 10px;
}

.form-row input{
  /* border: 1px solid rgba(112, 112, 112, 0.5); */
  color: #000000;
  font-weight: 400;
  font-size: 1rem;
  font-family: "Exo",Arial, Helvetica, sans-serif;
}

.form-control .input-date{
  text-align: right;
  border: 1px solid rgba(112, 112, 112, 0.5);
  background-image: url(/static/media/icon-calendar1.694873ad.svg);
  background-repeat: no-repeat;
  background-position: top 50% left 10px;
  padding-left: 50px;
  size: 15;
}

.input-date{
  text-align: right;
  border: 1px solid rgba(112, 112, 112, 0.5);
  background-image: url(/static/media/icon-calendar1.694873ad.svg);
  background-repeat: no-repeat;
  background-position: top 50% left 10px;
}

.form-row .inputCash{
  text-align: right;
  border: 1px solid rgba(112, 112, 112, 0.5);
  background-image: url(/static/media/icon-puce.22eabad3.svg);
  background-repeat: no-repeat;
  background-position: top 50% left 10px; 
}

.btn-submit{
  border: 1px solid rgba(112, 112, 112, 0.5);
  background-color: #FFFFFF;
  border-radius: .25rem;
  height: 35px;
  width: 200px;
  align-items: center;
  margin-left: 5px;
}

.btn-submit span{
  vertical-align: middle;
  margin-left: 15px;
}

.btn-cancel{
  border: 1px solid rgba(112, 112, 112, 0.5);
  background-color: #FFFFFF;
  border-radius: .25rem;
  height: 35px;
  width: 200px;
  align-items: center;
  margin-left: 5px;
}

.btn-cancel span{
  vertical-align: middle;
  margin-left: 15px;
}

/* .form-data > form > div:nth-of-type(2) {
  box-sizing: border-box;
} */

.container-img-invoice{
  background-color: rgba(0, 0, 0, .4);
  height: 100vh;
}

.container-logo{
  display:flex;
  flex-direction: row;
}

.title-status{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 5px;
}

.title-status > span:nth-of-type(1){
  color: #000000;
  font-weight: bold;
  font-family: "Exo",Arial, Helvetica, sans-serif;
}

.title-status > span:nth-of-type(2){
  color: #555555;
  font-weight: bold;
  font-family: "Exo",Arial, Helvetica, sans-serif;
  font-size: 0.5rem;
}


.container-img-invoice .container-fluid{
  -webkit-transform: scale(0.7) translate(0%, -15%);
          transform: scale(0.7) translate(0%, -15%);
  zoom: 115%;
}

.container-img-invoice > div:first-of-type{
  display: flex;
  flex-direction: column;
  height: 640px;
}

.form-data > div:nth-of-type(8) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-data > div:nth-of-type(8) .loader{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-data > div:nth-of-type(8) .loader div{
  margin-left: 5px;
}

.swal-custom-notif{
  color: #555555;
  font-family: "Exo",Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
}

.datepicker-form{
  font-family: "Exo",Arial, Helvetica, sans-serif;
}

.form-data > div:nth-of-type(3) {
  border: 0;
  margin-top: 15px;
  margin-bottom: 5px;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #F2F2F2;
  font-family: "Exo",Arial, Helvetica, sans-serif;
  color: #555555;
  height: 100px;
  border-radius: .25rem;
}

.highlight{
  font-weight: bold;
}

.name {
  margin-left: 40px;
  line-height: 20px;
}

.suggestion-content {
  background-repeat: no-repeat;
}

.bank {
  background-image: url(/static/media/bank.d85d882e.svg);
}

.transport {
  background-image: url(/static/media/transport.207b766c.svg);
}

.fees {
  background-image: url(/static/media/fees.c29c5199.svg);
}

.billing {
  background-image: url(/static/media/billing.d00f1d84.svg);
}

.social {
  background-image: url(/static/media/social.dd7c11f4.svg);
}

.treasury {
  background-image: url(/static/media/treasury.c7b68c64.svg);
}

/* All about the sytle of the date picker, to change its appearance online */

.react-date-picker__wrapper {
  border: none;
  width: inherit;
}

.react-date-picker__inputGroup {
  justify-content: flex-end;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.styles_overlay__CLSq-{
    height: 100vh !important;
    padding: 0;
}
.styles_modal__gNwvD{
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    max-width: 100%;
    padding: 0;
    background-color: #FFFFFF
}

.sc-bdVaJa{
    -webkit-transform: rotate(270deg) translate(-1990%, -530%);
            transform: rotate(270deg) translate(-1990%, -530%);
    border-radius: 70px;
    /* border: 0.5px solid #FFFFFF;
    background-color: transparent; */
}

/* .sc-bdVaJa svg path{
    background: transparent !important;  
} */

.sc-bdVaJa div:nth-of-type(2) > svg{
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    border: 1px solid #FFFFFF;
}

.sc-bdVaJa div:nth-of-type(2){
    border-top: none;
    /* border-bottom: none; */
}

.styles_modal__gNwvD > div:first-of-type{  
    height: 100vh;
}

.swal-custom-notif{
    color: #555555;
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
}


.backlog .headerContent{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 130px;
    border-bottom: 0.5px solid rgba(125,125,125,.2);
    box-sizing: border-box;
}



