.NavLink{
    z-index: 10;
    position: fixed;
    left: 0; top: 0;
    margin: 0;
    padding: 80px 0 0 0;
    width: 130px;
    height: 100vh;
    background-color: #FFFFFF;
    box-shadow: 1px 0 3px rgba(0,0,0,.1); 
}

