.backlog .headerContent{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 130px;
    border-bottom: 0.5px solid rgba(125,125,125,.2);
    box-sizing: border-box;
}

