.styles_overlay__CLSq-{
    height: 100vh !important;
    padding: 0;
}
.styles_modal__gNwvD{
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    max-width: 100%;
    padding: 0;
    background-color: #FFFFFF
}

.sc-bdVaJa{
    transform: rotate(270deg) translate(-1990%, -530%);
    border-radius: 70px;
    /* border: 0.5px solid #FFFFFF;
    background-color: transparent; */
}

/* .sc-bdVaJa svg path{
    background: transparent !important;  
} */

.sc-bdVaJa div:nth-of-type(2) > svg{
    transform: rotate(90deg);
    border: 1px solid #FFFFFF;
}

.sc-bdVaJa div:nth-of-type(2){
    border-top: none;
    /* border-bottom: none; */
}

.styles_modal__gNwvD > div:first-of-type{  
    height: 100vh;
}

.swal-custom-notif{
    color: #555555;
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
}

