@import url('https://fonts.googleapis.com/css?family=Exo:100,200,300,300i,400,500,600,700,900&display=swap');

.App {
  font-family: "Exo",Arial, Helvetica, sans-serif;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.pl-20{
  padding-left: 20px;
}
.pt-20{
  padding-top: 20px;
}

.pr-20{
  padding-right: 20px;
}
.ml-20{
  margin-left: 20px;
}

.ml-15{
  margin-left: 15px;
}

.mr-20{
  margin-right: 20px;
}
.mt-20{
  margin-top: 20px;
}
.separator{
  border-bottom: 0.5px solid rgba(152, 152, 152, .3);
}
.text-center-h{
  text-align: center;
}
.text-right-h{
  text-align: right;
}

.text-left-h{
  text-align: left;
}

.text-left-h-description{
  text-align: left;
}

.font-size-table-data{
  font-size: 0.9rem;
  font-family: "Exo",Arial, Helvetica, sans-serif;
}

.deliver-style{
  font-size: 0.9rem;
  font-weight: 600;
  color:#000000;
  font-family: "Exo",Arial, Helvetica, sans-serif;
}

.mr-pag-15{
  margin-right: 15px;
}
.mt-pag-15{
  margin-top: 15px;
}
button, input, select, button:focus, input:focus, select:focus{
  outline: none !important;
}