.container-footer{
    background-color: transparent;
    margin-bottom: 15px;
    margin-top: 25px;
}

.footer{
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 0.7rem;
    font-weight: 500;
    color: #000000;
    width: 316px;
}