.container-pagination{
    display: flex;
    justify-content: space-between;
    height: 35px;
    margin-top: 20px;
}
.container-pagination button{
    outline: none;
}
.pagination-left{
    display: flex;
    justify-content: space-between;
}

.onePage{
    border: 0.5px solid #555555;
    border-radius: 5px;
    height: 27px;
    width: 27px;
    margin-right: 15px; 
    color: #555555;
}

.container-pagination .active{
    border: 0.5px solid #DB1B1B;
    color: #DB1B1B;
}

.pagination-rigth{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 38px;
    min-width: 100px;
    color: #555555;
    font-size: 0.8rem;
}
.pagination-rigth .page-range{
    margin-right: 20px;
}
.pagination-rigth .dropdown{
    border: 0.5px solid #555555;
    border-radius: 25px;
    font-size: 0.8rem;
    width: 50px;
}
.pagination-rigth .dropdown-toggle{
    padding: 0 10px !important;
    font-size: 0.8rem;
}
.pagination-rigth .dropdown-toggle::after {
    display: inline-block;
    margin-left: 1.055em;
    vertical-align: .155em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

.nb-items{
    border-radius: 15px;
}


#page-numbers {
    list-style: none;
    display: flex;
}
  
#page-numbers > li {
    margin-right: 0.3em;
    color: blue;
    user-select: none;
    cursor: pointer;
}

:disabled,
.disabled > img{
    outline: none;
    cursor: not-allowed;
    pointer-events: none;
    color:#fff;
    border-color: #a0a0a0;
    background-color: rgb(229, 229, 229) !important;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.disabled:focus{
    outline: none;
}

