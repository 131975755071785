button.NavItem{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 130px;
    background-color: #FFFFFF;
    border-top: none;
    border-right: none;
    border-bottom: 0.5px solid rgba(0,0,0, .2);
    border-left: none;
    cursor: pointer;
    outline: none;
}

.content-btn-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
button.NavItem .label{
    margin-top: 15px; 
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    color: black;
}

button.NavItem.active .label{
    margin-top: 15px; 
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 0.8rem;
    font-weight: 900;
    color: #1A45D3;
}

button.NavItem.active::after{
    position: absolute;
    content: " ";
    display: block;
    top: 5%; bottom: 5%; left: 0;
    width: 5px;
    background-color: #1A45D3;
    border-radius: 15px;
}
