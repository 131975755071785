.tag-description{
    display: flex;
    align-items: center;
    border-radius: 15px;
    border: 1px solid ;
    box-sizing: border-box;
    padding-right: 5px;
    padding-left: 5px;
    margin-right: 10px;
    margin-bottom: 5px;
    color: #AAA7A7;
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 12px;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
  }

  .tag-description img{
    margin-right: 5px;
  }

  .ct-tag{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }