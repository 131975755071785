table.table-data{
   position: relative;
   width: 100%;
   font-family: "Exo", Arial, Helvetica, sans-serif;
   font-size: 0.8rem;
   border-collapse:separate; 
   border-spacing: 0 5px;
}
table.table-data > thead > tr{
    border: 0.5px solid rgba(125, 125, 125, 0.2);
}
/* table.table-data > thead > tr > th:first-child{
   text-align: center;
} */
table.table-data tr{
    height: 50px;
    background-color: #FFFFFF;
    margin-bottom: 10px;
    box-sizing: border-box;
    border: 0.5px solid rgba(125, 125, 125, 0.2);
    cursor: pointer;
    -moz-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    white-space: nowrap;
}

table.table-data tbody tr:hover{
    box-shadow: 0 0 1px rgba(0,0,0,.6);
    border: 0.5px solid #707070;
}

.total-tva{
    color: #F56969;
}

