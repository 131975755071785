.container-fluid .container-fluid{
  transform: scale(0.7) translate(0%, -15%);
  zoom: 115%;
}

.form-data{
  margin-top: 25px;
  font-family: "Exo",Arial, Helvetica, sans-serif;
  color: #555555;
  font-size: 1em;
  font-weight: 500;
}

form > div:nth-of-type(2){
  box-sizing: border-box;
  border: 0.3px solid rgba(112, 112, 112, 0.3);
  /* margin-top: 10px; */
}

form > div:nth-of-type(3){
  margin-top: 20px;
}

form > div:nth-of-type(4){
  margin-top: 10px;
}

.form-row input{
  /* border: 1px solid rgba(112, 112, 112, 0.5); */
  color: #000000;
  font-weight: 400;
  font-size: 1rem;
  font-family: "Exo",Arial, Helvetica, sans-serif;
}

.form-control .input-date{
  text-align: right;
  border: 1px solid rgba(112, 112, 112, 0.5);
  background-image: url('../../assets/svg/icon-calendar1.svg');
  background-repeat: no-repeat;
  background-position: top 50% left 10px;
  padding-left: 50px;
  size: 15;
}

.input-date{
  text-align: right;
  border: 1px solid rgba(112, 112, 112, 0.5);
  background-image: url('../../assets/svg/icon-calendar1.svg');
  background-repeat: no-repeat;
  background-position: top 50% left 10px;
}

.form-row .inputCash{
  text-align: right;
  border: 1px solid rgba(112, 112, 112, 0.5);
  background-image: url('../../assets/svg/icon-puce.svg');
  background-repeat: no-repeat;
  background-position: top 50% left 10px; 
}

.btn-submit{
  border: 1px solid rgba(112, 112, 112, 0.5);
  background-color: #FFFFFF;
  border-radius: .25rem;
  height: 35px;
  width: 200px;
  align-items: center;
  margin-left: 5px;
}

.btn-submit span{
  vertical-align: middle;
  margin-left: 15px;
}

.btn-cancel{
  border: 1px solid rgba(112, 112, 112, 0.5);
  background-color: #FFFFFF;
  border-radius: .25rem;
  height: 35px;
  width: 200px;
  align-items: center;
  margin-left: 5px;
}

.btn-cancel span{
  vertical-align: middle;
  margin-left: 15px;
}

/* .form-data > form > div:nth-of-type(2) {
  box-sizing: border-box;
} */

.container-img-invoice{
  background-color: rgba(0, 0, 0, .4);
  height: 100vh;
}

.container-logo{
  display:flex;
  flex-direction: row;
}

.title-status{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 5px;
}

.title-status > span:nth-of-type(1){
  color: #000000;
  font-weight: bold;
  font-family: "Exo",Arial, Helvetica, sans-serif;
}

.title-status > span:nth-of-type(2){
  color: #555555;
  font-weight: bold;
  font-family: "Exo",Arial, Helvetica, sans-serif;
  font-size: 0.5rem;
}


.container-img-invoice .container-fluid{
  transform: scale(0.7) translate(0%, -15%);
  zoom: 115%;
}

.container-img-invoice > div:first-of-type{
  display: flex;
  flex-direction: column;
  height: 640px;
}

.form-data > div:nth-of-type(8) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-data > div:nth-of-type(8) .loader{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-data > div:nth-of-type(8) .loader div{
  margin-left: 5px;
}

.swal-custom-notif{
  color: #555555;
  font-family: "Exo",Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
}

.datepicker-form{
  font-family: "Exo",Arial, Helvetica, sans-serif;
}

.form-data > div:nth-of-type(3) {
  border: 0;
  margin-top: 15px;
  margin-bottom: 5px;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #F2F2F2;
  font-family: "Exo",Arial, Helvetica, sans-serif;
  color: #555555;
  height: 100px;
  border-radius: .25rem;
}

.highlight{
  font-weight: bold;
}

.name {
  margin-left: 40px;
  line-height: 20px;
}

.suggestion-content {
  background-repeat: no-repeat;
}

.bank {
  background-image: url('./img/bank.svg');
}

.transport {
  background-image: url('./img/transport.svg');
}

.fees {
  background-image: url('./img/fees.svg');
}

.billing {
  background-image: url('./img/billing.svg');
}

.social {
  background-image: url('./img/social.svg');
}

.treasury {
  background-image: url('./img/treasury.svg');
}

/* All about the sytle of the date picker, to change its appearance online */

.react-date-picker__wrapper {
  border: none;
  width: inherit;
}

.react-date-picker__inputGroup {
  justify-content: flex-end;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}