.tri-search{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.title-tri{
    display: flex;
    align-items: flex-end;
    color: #555555;
    font-weight: bold;
    font-family: "Exo",Arial, Helvetica, sans-serif;
    font-size: 0.7rem; 
    vertical-align: bottom;
    margin-right: 9px;
}

.content-left{
    display: flex;
}

.content-ct1{
    background-color: #F8F8F8;
    position: relative;
}



