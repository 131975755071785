.header{
   z-index: 11;
   position: fixed;
   display:flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   left: 0; top: 0;
   width: 100%;
   height: 80px;
   margin: 0; padding: 0;
   background-color: #FFFFFF;
   box-shadow: 0 1px 3px rgba(0,0,0,.1);
}
.header .logo{
   display: flex;
   align-items: center;
   justify-content: center;
   width: 130px;
   height: 80px;
   color: #555555;
   font-size: 1.3rem;
}
.bold{
   font-weight: bold;
}
.bloc{
   display: block;
}
.noti{
   position: relative;
   margin: 0 50px 0 0;
   padding: 0;
   width:30px;
   height:30px;
   background-image: url(../../assets/svg/bell.svg);
   /* background-size: cover; */
   background-position: center;
   background-repeat: no-repeat;
   transform: scale(0.8)
}
.nb-noti{
   position: absolute;
   top:-13px;
   right: -13px;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 18px;
   border: thin solid #DB1B1B;
   border-radius: 50%;
   height: 18px;
   font-size: 0.8rem;
   font-weight: 800;
   color: #DB1B1B;
}
.right{
   display: flex;
   align-items: center;
   justify-content: center;
   margin-right: 30px;
}
.usr-block{
   display: flex;
   align-items: center;
}
.label-user{
   margin: 0 10px;
   font-weight: 300;
   font-size: 0.9rem;
   font-family: "Exo",Arial, Helvetica, sans-serif;
   color: #707070;
}