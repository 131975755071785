.DateItemList{
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border: 0.5px solid #707070;
    color:  #333333 ;
    overflow: hidden;
    font-family:  "Exo" ;
    transform: scale(0.8);
}
.DateItemList.active{
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border: none;
    color:  #333333 ;
    overflow: hidden;
    font-family:  "Exo" ;
    transform: scale(0.8);
}
.DateItemList .boxDay{
    display: flex;
    height: 30px;
    background-color: #CBCBCB;
    align-items: center;
    justify-content: center ;
}
.DateItemList .boxDayactive{
    display: flex;
    height: 30px;
    background-color:  #F56969 ;
    align-items: center ;
    justify-content: center ;
}
.DateItemList .boxMonth{
    display:  flex ;
    height: 20px;
    align-items: center ;
    justify-content: center ;
    border-top: 0.5px solid #707070;
    background-color:#AAA7A7 ;
}
.DateItemList .boxMonthactive{
    display: flex ;
    height: 20px;
    align-items: center ;
    justify-content: center ;
    background-color:#E23E3E ;
    border-top: 0.5px solid rgba(255,255,255,0.7);
}
.DateItemList .txtDay{
   font-family: "Exo" ;
   font-size: 23px;
   font-weight: bold ;
   color:  #333 ;
   margin-top: 5px;
}
.boxDayactive .txtDay{
    color:  #FFF ; 
}
.DateItemList .txtMonth{
   font-family: "Exo" ;
   font-size: 12px;
   font-weight: 900 ;
   color:  #333 ;
   text-transform: capitalize;
}
.boxMonthactive .txtMonth{
    color:  #FFF ; 
}